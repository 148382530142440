.mini-chart {
    width: 100%;
    height: 100%;
    // display: block;
    border-color: #3d4552;
    border-width: 1px;
    border-style: solid;
    border-radius: 10px;
    padding: 8px;
    gap: 8px;
    background-color: #2f343f;

    div.mini-chart-header {
        height: 44px;

        div.mini-chart-symbol-name {
            color: #ffffff;
            font-weight: 400;
            font-size: 14px;
            height: 12px;
            display: block;
            line-height: 14px;
        }
        div.mini-chart-stat {
            height: 24px;
            margin-top: 8px;
        }
        div {
            span.mini-chart-symbol-price {
                color: #ffffff;
                font-weight: normal;
                font-size: 14px;
            }
        }
    }

    div.mini-chart-container {
        width: 100%;
        height: calc(100% - 44px);
        display: block;
    }

    .lds-roller {
        display: inline-block;
        position: relative;
        width: 80px;
        height: 80px;
        z-index: 100001;
    }
    .lds-roller div {
        animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        transform-origin: 40px 40px;
    }
    .lds-roller div:after {
        content: " ";
        display: block;
        position: absolute;
        width: 7px;
        height: 7px;
        border-radius: 50%;
        background: #a3a3a3b0;
        margin: -4px 0 0 -4px;
    }
    .dark-theme .lds-roller div:after {
        background: #fff;
    }
    .lds-roller div:nth-child(1) {
        animation-delay: -0.036s;
    }
    .lds-roller div:nth-child(1):after {
        top: 63px;
        left: 63px;
    }
    .lds-roller div:nth-child(2) {
        animation-delay: -0.072s;
    }
    .lds-roller div:nth-child(2):after {
        top: 68px;
        left: 56px;
    }
    .lds-roller div:nth-child(3) {
        animation-delay: -0.108s;
    }
    .lds-roller div:nth-child(3):after {
        top: 71px;
        left: 48px;
    }
    .lds-roller div:nth-child(4) {
        animation-delay: -0.144s;
    }
    .lds-roller div:nth-child(4):after {
        top: 72px;
        left: 40px;
    }
    .lds-roller div:nth-child(5) {
        animation-delay: -0.18s;
    }
    .lds-roller div:nth-child(5):after {
        top: 71px;
        left: 32px;
    }
    .lds-roller div:nth-child(6) {
        animation-delay: -0.216s;
    }
    .lds-roller div:nth-child(6):after {
        top: 68px;
        left: 24px;
    }
    .lds-roller div:nth-child(7) {
        animation-delay: -0.252s;
    }
    .lds-roller div:nth-child(7):after {
        top: 63px;
        left: 17px;
    }
    .lds-roller div:nth-child(8) {
        animation-delay: -0.288s;
    }
    .lds-roller div:nth-child(8):after {
        top: 56px;
        left: 12px;
    }
    @keyframes lds-roller {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
}
