.PercentTagUp {
    background-color: rgba(45, 84, 75, 1);
    border-radius: 10px;
    padding-left: 4px;
    padding-top: 2px;
    padding-right: 4px;
    padding-bottom: 2px;
    gap: 2px;
    color: #27AF8F;
    font-size: 14px;
    margin: 4px 4px 4px 4px;
}



.PercentTagDown {
    background-color: #C83D4D;
    border-radius: 10px;
    padding-left: 4px;
    padding-top: 2px;
    padding-right: 4px;
    padding-bottom: 2px;
    gap: 2px;
    color: #FFFFFF;
    font-size: 14px;
    margin: 4px 4px 4px 4px;
}

.PercentTagUp , .PercentTagDown  {
    svg{
        display: inline-block;
        margin: 1px 4px 4px 4px;
    }
}